/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    h3: "h3",
    pre: "pre",
    code: "code",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Notes and Reference SQL Language"), "\n", React.createElement(_components.h3, null, "Callback Pattern Sqlite queries"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "//....\nimport db from './config/db'\n\n//....\n\n//....\n/**\n * Read all records and all their columns from some given table.\n */\nconst read = async ({ table }) => {\n\tconst stmt = `SELECT * FROM ${table}`\n\tlet res = {}\n\t// all \n  db.all(stmt, (error, data) => {\n    logger.info('---all');\n    console.log(`${JSON.stringify(data)} `);\n  });\n  return res;\n};\n\nconst read = async ({ table }) => {\n\tconst stmt = `SELECT * FROM ${table}`\n\t// all \n\tlet res = {}\n  db.each(stmt, (error, row) => {\n\t  logger.info('--each');\n\t// This will be printed everytime a row is returned\n\t\tconsole.log(`${JSON.stringify(row)} `);\n\t});\n  return res;\n};\n\n")), "\n", React.createElement(_components.h3, null, "Async Await Pattern with Sqlite queries"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "//....\nimport db from './config/db'\nimport { promisify } from \"util\";\n//....\n\n// Use the promise pattern for SQLite so we don't end up in callback hell.\nconst query = promisify(db.all).bind(db);\n\n//....\n/**\n * Read all records and all their columns from some given table.\n */\nconst read = async ({ table }) => {\n\tconst stmt = `SELECT * FROM ${table}`\n  const res = await query(stmt);\n  return res;\n};\n\n//....\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.grouparoo.com/blog/understanding-types-sqlite-node"
  }, "Understaing ")), "\n", React.createElement(_components.h2, null, "Resources and Reference"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.codecademy.com/learn/connecting-javascript-and-sql/modules/learn-node-sqlite-module/cheatsheet"
  }, "Codecademy Sqlite Cheatsheet")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
